import React, { useState } from "react";

// STYLE
import "./routeCards.scss";

// COMPONENTS
import { Row, Col } from "reactstrap";

import Heading from "../../atoms/Heading/Heading";
import FormElement from "../../atoms/FormElement/FormElement";
import RouteCard from "../../molecules/RouteCard/RouteCard";
import useRoutes from "../../../hooks/redux/useRoutes";

const RouteCards = () => {
    // STATE
    const [changeView, setChangeView] = useState(false);
    const [{ routes }] = useRoutes();

    const handleChangeView = () => setChangeView(!changeView);

    return (
        <div className="route-cards">
            <div className="route-cards--top">
                <Heading as="h2">{`Wszystkie trasy (${routes.length})`}</Heading>
                <FormElement
                    type="checkbox"
                    name="view"
                    id="view"
                    checked={changeView}
                    onChange={handleChangeView}
                    label="Zmień widok listy tras"
                />
            </div>
            <Row className="route-cards--items">
                {routes?.map((route, index) => (
                    <Col
                        key={route.id}
                        xs={!changeView ? 6 : 12}
                        lg={!changeView ? 4 : 12}
                        className="route-cards--item-container"
                    >
                        <RouteCard
                            className={!changeView && "route-card--sm"}
                            route={route}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default RouteCards;
